<template>
  <div fluid style="height: calc(100vh - 48px)">
    <div class="mx_calendar_header">
      <div class="mx_calendar_header__date">
        <div><strong>{{ current_month }}</strong></div>
        <div>{{ current_year }}</div>
      </div>
      <div class="mx_calendar_header__action">actions</div>
    </div>
    <div class="mx_calendar_header__week_days">
      <div class="mx_calendar_header__week_days--item" v-for="(d, i) in week_days" :key="i">{{ d }}</div>
    </div>
    <div class="mx_calendar">
      <div
          class="mx_calendar_item"
          :class="{
            weekend: isWeekend(day),
            not_current_month: !isCurrentMonth(day),
            active_item: active_item === day.format('YYYY-MM-DD') && isCurrentMonth(day)
          }"
          v-for="(day, index) in days_array"
          :key="index"
          @click.stop="activeItem(day)"
      >
        <div class="mx_calendar_item__date">
          <div class="default" :class="{current_day: day.format('YYYY-MM-DD') === current_day}">
            <span>{{ day.format('D') }}</span>
          </div>
        </div>
<!--        <ul v-if="day.format('D')  === '6' || day.format('D')  === '13' || day.format('D')  === '24'" class="mx_calendar_item__list">-->
<!--          <li>aufgaben 1</li>-->
<!--          <li>aufgaben 2</li>-->
<!--          <li>aufgaben 2</li>-->
<!--          <li>aufgaben 2</li>-->
<!--          <li>aufgaben 2</li>-->
<!--          <li>aufgaben 2</li>-->
<!--          <li>aufgaben 2</li>-->
<!--          <li>aufgaben 2</li>-->
<!--          <li>aufgaben 2</li>-->
<!--          <li>aufgaben 2</li>-->
<!--        </ul>-->
      </div>

    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      active_item: null,
      days_array: [],
      current_year: 2021,
      current_month: 'April',
      current_day: null,
      week_days: [
          'Montag',
          'Dienstag',
          'Mittwoch',
          'Donnerstag',
          'Freitag',
          'Samstag',
          'Sonntag'
      ]
    }
  },
  created() {
    this.getCurrentYear()
    this.getCurrentMonth()
    this.getCurrentDay()
    this.calendar()
  },
  computed: {},
  methods: {
    calendar() {
      const totalDays = 42
      this.days_array = [...Array(totalDays)]
      this.days_array = this.getDays()

    },
    getDays() {
      moment.updateLocale('de', {
        week: {dow: 1} // Начало недели с понедельника (По стандарту начинается неделя с воскресения)
      })

      let start_day = moment().startOf('month').startOf('week')
      let end_day = moment().endOf('month').endOf('week')

      let day = start_day.subtract(1, 'day').clone()

      return this.days_array.map(() => day.add(1, 'day').clone())

    },
    isWeekend(day) {
      return day.day() === 6 || day.day() === 0
    },

    isCurrentMonth(day) {
      console.log(day);
      return day.format('MMMM') === this.current_month
    },
    getCurrentMonth() {
      this.current_month = moment().format('MMMM')
    },
    getCurrentYear() {
      this.current_year = moment().format('YYYY')
    },
    getCurrentDay() {
      this.current_day = moment().format('YYYY-MM-DD')
    },
    activeItem(day) {
      this.active_item = day.format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="scss">

:root {
  --bg: #404040;
  --bg-item: #272829;
  --bg-header: #272829;
  --bg-item-active: #4f4f4f ;
  --bg-not_current_month: #303030;
  --color-not_current_month: #606060;
  --bg-weekend: #323232;
  --color: #e3e3e3;
}

.mx_calendar_header {
  height: 40px;
  background: var(--bg-header);
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #ffffff;
  padding: 0 8px;

  &__date {
    display: flex;
    color: #ffffff;
    font-size: 16px;
    div {
      margin-right: 8px;
    }
  }

  &__week_days {
    height: 40px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    //grid-template-rows: repeat(6, 1fr);
    grid-gap: 1px;
    background: var(--bg-header);
    border-top: 1px solid var(--bg);
    &--item {
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.mx_calendar {
  height: calc(100% - 40px);
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 1px;
  background: var(--bg);
  border: 1px solid var(--bg);

  &_item {
    min-width: 140px;
    min-height: 80px;
    background: var(--bg-item);
    color: var(--color);
    padding: 16px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &.weekend {
      background: var(--bg-weekend);
    }
    &.not_current_month {
      color: var(--color-not_current_month);
      background: var(--bg-not_current_month);
      cursor: default;
    }
    &.active_item {
      background-color: var(--bg-item-active);
    }
    &__date {
      min-height: 33px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 8px;
      text-align: right;
      .default {
        span {
          margin-right: 8px;
        }
      }
      .current_day {
        background: red;
        height: 33px;
        width: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 80px;
        margin-left: auto;
        span {
          display: block;
          margin-right: 1px;
        }
      }
    }
    &__list {
      scrollbar-color: #292929 #4a4a4a !important;
      list-style: none;
      padding: 0 !important;
      margin: 0 !important;
      overflow-y: auto;
      min-height: 60px;
      li {
        padding: 2px;
        margin-bottom: 2px;
        font-size: 14px;
        background: #212121;
      }
    }
  }
}
</style>
